import { AuthStore, authStore } from "./auth.store";
import Parse from "parse";
import { displayAlert as toast } from "@utils/snackbar.utils";

export class AuthService {
  constructor(private store: AuthStore) {}

  login = async (mail: string, password: string) => {
    this.store.setLoading(true);
    try {
      const user = await Parse.User.logIn(mail, password);
      if (Parse.User.current()?.get("emailVerified") !== true) {
        Parse.User.logOut();
        toast.error(
          "Vous devez confirmer votre e-mail avant de vous connecter",
          {
            variant: "error",
          }
        );
      } else {
        toast.success("Vous êtes connecté(e) !", {
          variant: "success",
        });
        window.location.reload();
      }
    } catch (error: any) {
      if (error.code === 101) {
        toast.error("Le nom d'utilisateur ou le mot de passe est invalide", {
          variant: "error",
        });
      } else {
        toast.error("Une erreur est survenue", {
          variant: "error",
        });
      }
    }
    this.store.setLoading(false);
  };

  register = async (
    name: string,
    mail: string,
    pass: string,
    confirm: string,
    birthday?: Date
  ) => {
    this.store.setLoading(true);
    if (pass === confirm) {
      const user = new Parse.User();
      user.set("password", pass);
      user.set("firstName", name);
      user.set("birthday", birthday);
      user.set("email", mail);
      user.set("username", mail);
      user.set("authData", undefined);
      try {
        const userResult = await user.signUp();
        toast.success("Votre compte a bien été enregistré !", {
          variant: "success",
        });
        localStorage.setItem("register", "registerSuccess");
      } catch (error: any) {
        if (error.code === 202) {
          toast.error(
            "Ce compte existe déjà, veuillez en créer un autre ou vous connecter",
            { variant: "error" }
          );
        } else {
          toast.error("Une erreur est survenue", { variant: "error" });
        }
      }
    } else {
      toast.error(
        "Le mot de passe et le mot de passe de confirmation doivent être identiques !",
        { variant: "error" }
      );
    }
    Parse.User.logOut();
    this.store.setLoading(false);
  };

  resetPassword = async (email: string) => {
    this.store.setLoading(true);
    try {
      await Parse.User.requestEmailVerification(email);
      await Parse.User.requestPasswordReset(email);
      toast.success("Votre email a bien été envoyé !", {
        variant: "success",
      });
    } catch (error: any) {
      if (error.code === 205) {
        toast.error(
          "L'adresse email est invalide, veuillez saisir l'adresse mail saisie lors de votre inscription.",
          {
            variant: "error",
          }
        );
      } else if (error.code === -1) {
        await Parse.User.requestPasswordReset(email);
        toast.success("Votre email a bien été envoyé !", {
          variant: "success",
        });
      } else {
        toast.error("Une erreur est survenue", {
          variant: "error",
        });
      }
    }
    this.store.setLoading(false);
  };
}

export const authService = new AuthService(authStore);
