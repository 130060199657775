export enum RoutesPath {
  DEMO = "demo",
  LOGIN = "login",
  REGISTER = "register",
  CODEBOX = "codebox",
  OFFER = "offer",
  RENAULTOFFER = "4573RY6E54N345A543UB44D46L3T4offer",
  RENAULTLOGIN = "4573RY6E54N3478eEF3UB44D46L3T4login",
  RENAULTREGISTER = "4573RY5E45FV3UB44D46L3T4register",
}
