import React from "react";
import { RoutesPath } from "@utils/enums/routes.utils";
import { Route, Switch, Redirect } from "react-router";
import Codebox from "./codebox";
import OfferScreen from "./offer/offer.screen";
import RenaultOfferScreen from "./renaultOffer/renaultOffer.screen";
import NullScreen from "./404/null.screen";

const Auth = () => {
  return (
    <Switch>
      <Route exact path={`/${RoutesPath.CODEBOX}`}>
        <Codebox />
      </Route>
      <Route exact path={`/${RoutesPath.OFFER}`}>
        <OfferScreen />
      </Route>
      <Route exact path={`/null`}>
        <NullScreen />
      </Route>
      <Route
        exact
        path={`/${
          localStorage.getItem("renault") ===
            process.env.REACT_APP_RENAULTCODE! && RoutesPath.RENAULTOFFER
        }`}
      >
        <RenaultOfferScreen />
      </Route>
      <Redirect
        to={`/${
          localStorage.getItem("renault") === process.env.REACT_APP_RENAULTCODE!
            ? RoutesPath.RENAULTOFFER
            : localStorage.getItem("path")
        }`}
      />
    </Switch>
  );
};

export default Auth;
