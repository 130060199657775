import { CodeboxStore, codeboxStore } from "./codebox.store";
import Parse from "parse";
import { displayAlert as toast } from "@utils/snackbar.utils";
import { renaultOfferEnd, renaultOfferEndString } from "@utils/date.utils";

export class CodeboxService {
  constructor(private store: CodeboxStore) {}

  sendCode = async (codeValue: string) => {
    const params = { promoCode: codeValue };
    this.store.setLoading(true);
    try {
      const result = await Parse.Cloud.run("verifyCode", params);
      const jsonResult = JSON.parse(result);
      if (jsonResult.success === true) {
        toast.success("Votre code a été validé.", {
          variant: "success",
        });
        this.store.update(jsonResult);
        localStorage.setItem("codeSuccess", "success");
      } else {
        if (jsonResult.error.code === 404) {
          toast.error(
            "Le code n'a pas été identifié, veuillez mettre un code valide!",
            {
              variant: "error",
            }
          );
        } else if (jsonResult.error.code === 400) {
          toast.error(
            "Le code a déjà été utilisé, veuillez en saisir un autre",
            {
              variant: "error",
            }
          );
        } else {
          toast.error(
            "Une erreur est survenue, veuillez contacter l'assistance technique",
            {
              variant: "error",
            }
          );
        }
        localStorage.setItem("codeSuccess", "error");
      }
    } catch (error: any) {
      toast.error("Une erreur est survenue", {
        variant: "error",
      });
      localStorage.setItem("codeSuccess", "error");
    }
    this.store.setLoading(false);
  };

  createUserSubscription = async () => {
    const params = { promoCodeDuration: localStorage.getItem("cardStatus") };
    this.store.setLoading(true);
    const user = await Parse.User.currentAsync();
    try {
      const result = await Parse.Cloud.run("createUserSubscription", params);
      user?.set("freeTestStarted", true);
      const jsonResult = JSON.parse(result);
      if (jsonResult.success === true) {
        toast.success("Votre paiement a été effectué.", {
          variant: "success",
        });
      } else {
        toast.error(
          "Une erreur est survenue, veuillez contacter l'assistance technique",
          {
            variant: "error",
          }
        );
      }
    } catch (error: any) {
      toast.error("Une erreur est survenue", {
        variant: "error",
      });
    }
    this.store.setLoading(false);
  };

  renaultFreeOffer = async () => {
    this.store.setLoading(true);
    const user = await Parse.User.currentAsync();
    const userOfferStart: Date = await user?.get("renaultOfferStart");
    const userSubscription = await user?.get("currentSubscription");
    const endDate: Date = await userSubscription?.get("endDate");
    const params = { promoCodeDuration: "THREE_MONTH" };
    if (userOfferStart !== undefined) {
      if (
        userOfferStart &&
        userOfferStart.toLocaleDateString() < renaultOfferEnd(userOfferStart)
      ) {
        toast.error(
          `Oups! Il semblerait que vous ayez déjà activé cette offre! Elle prendra fin le ${renaultOfferEndString(
            userOfferStart
          )}`,
          {
            variant: "error",
          }
        );
        localStorage.setItem("renaultOfferDate", "between");
      } else {
        localStorage.setItem("renaultOfferDate", "end");
      }
    } else {
      if (
        userSubscription === undefined ||
        userSubscription.subscriptionAppleId !== "KabriolFrance" ||
        endDate.toLocaleDateString() < new Date().toLocaleDateString()
      ) {
        await Parse.Cloud.run("createUserSubscription", params);
      } else {
        endDate.setMonth(endDate.getMonth() + 3);
      }
      localStorage.setItem("renaultOfferDate", "start");
      user?.set("renaultOfferStart", new Date());
      user?.set("freeTestStarted", true);
    }
    try {
      user?.save();
    } catch (error: any) {
      toast.error("Une erreur est survenue", {
        variant: "error",
      });
    }
    this.store.setLoading(false);
  };
}

export const codeboxService = new CodeboxService(codeboxStore);
